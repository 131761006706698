<template>
  <b-container class="page-container">
    <bread-crumbs :currentpage="$t('log-in')" />
    <h1 class="text-center">{{ $t('log-in') }}</h1>
    <p>
      {{ $t('login-to-rent-bike') }}
    </p>
    <b-row>
      <b-col sm="12" class="mb-3"> </b-col>
      <b-col sm="12" lg="6" xl="6">
        <b-card>
          <login />
        </b-card>
      </b-col>
      <b-col sm="12" md="6" class="pt-4 pt-lg-0">
        <h4>{{ $t('no-login-details') }}?</h4>

        <router-link
          :to="{ name: 'register', params: { lang: this.$i18n.locale } }"
          class="btn btn-primary mt-3"
          register
          >{{ $t('register') }}</router-link
        >
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Login from '@/components/login/Login.vue'
export default {
  metaInfo: {
    title: `Inloggen`
  },
  components: {
    Login
  },
  created: function() {
    if (this.$route.params.registered) {
      this.notify({ message: this.$t('account-creation-succesfull') })
    }
  },
  methods: {
    notify({ message }) {
      this.$toasted.show(message)
    }
  }
}
</script>
<style scoped></style>
