<template>
  <b-form @submit.prevent="loginFormSubmit">
    <b-alert v-if="error" show variant="danger">
      {{ error }}
    </b-alert>
    <b-form-group
      id="input-group-1"
      :label="$t('emailaddress')"
      label-for="input-1"
    >
      <b-form-input
        id="input-1"
        v-model="form.username"
        :placeholder="$t('emailaddress')"
        type="text"
        required
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="input-group-2"
      :label="$t('password')"
      label-for="input-2"
    >
      <b-input-group class="mt-3">
        <b-form-input
          v-model="form.password"
          :placeholder="$t('password')"
          :type="showPassword ? 'text' : 'password'"
          required
        ></b-form-input>
        <b-input-group-append>
          <b-button
            :title="$t('showPassword')"
            @click="showPasswordToggle()"
            variant="primary"
            ><font-awesome-icon v-if="!showPassword" fas icon="eye"/>
            <font-awesome-icon v-if="showPassword" fas icon="eye-slash"
          /></b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-row>
      <b-col xs="12" sm="6">
        <b-button class="mb-2" type="submit" variant="primary"
          >{{ $t('login') }}
          <font-awesome-icon
            v-if="processing"
            :icon="['fad', 'spinner']"
            class="fa-spin"
            far
        /></b-button>
      </b-col>
      <b-col xs="12" sm="6">
        <router-link
          :to="{
            name: 'forgotPassword',
            params: { lang: this.$i18n.locale }
          }"
          class="float-right"
          >{{ $t('forgot-password') }}?</router-link
        >
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { login } from '@/services/AuthorizationService'

export default {
  components: {},
  data: function() {
    return {
      form: {
        username: '',
        password: ''
      },
      showPassword: false,
      processing: false,
      error: ''
    }
  },
  methods: {
    async loginFormSubmit() {
      this.processing = true

      const result = await login({
        username: this.form.username,
        password: this.form.password
      })

      if (result) {
        this.error = result
      }

      this.processing = false
    },
    showPasswordToggle() {
      if (this.showPassword) {
        this.showPassword = false
      } else {
        this.showPassword = true
      }
    }
  }
}
</script>

<style></style>
